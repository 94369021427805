<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">{{nav[0].name}}</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 20px">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="130px"
            >
              <el-form-item label="网格名称" prop="gridName">
                <el-input v-model="form.gridName" placeholder="请输入" maxlength="20" disabled></el-input>
              </el-form-item>
              <el-form-item label="所属标段" prop="bidId">
                <ayl-cascader-for-bid v-model="form.bidId" @change="handleBidChage" disabled></ayl-cascader-for-bid>
              </el-form-item>
              <el-form-item label="排序">
                <el-input v-model="form.sort" placeholder="请输入" maxlength="7" disabled></el-input>
              </el-form-item>
              <el-form-item label="网格类型" prop="gridType">
                <el-select v-model="form.gridType" placeholder="请选择" filterable disabled>
                  <el-option
                    v-for="item in gridTypeOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="网格负责人" prop="gridManager">
                <el-input v-model="form.gridManager" placeholder="请输入" maxlength="20" disabled></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="contactPhone">
                <el-input
                  v-model="form.contactPhone"
                  placeholder="请输入11位手机"
                  maxlength="11"
                  disabled
                ></el-input>
              </el-form-item>

              <el-form-item label="应排班人数" prop="scheduleWorkerNum">
                <el-input v-model="form.scheduleWorkerNum" placeholder="请输入" maxlength="7" disabled></el-input>
              </el-form-item>
              <el-form-item label="网格面积(m²)">
                <el-input v-model="form.gridArea" placeholder="请输入" maxlength="7" disabled></el-input>
              </el-form-item>
              <el-form-item label="保洁等级">
                <el-select
                  v-model="form.cleanLevel"
                  placeholder="请选择"
                  filterable
                  disabled
                >
                  <el-option
                    v-for="item in cleanLevelOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="'工作时段'" required>
                <el-col class="time-s" :span="11">
                  <el-form-item prop="workStartTime">
                    <el-time-select
                      disabled
                      placeholder="起始时间"
                      v-model="form.workStartTime"
                      :picker-options="{
                          start: '00:00',
                          step: '00:05',
                          end: '23:55'
                        }"
                      @change="timeChange"
                    ></el-time-select>
                  </el-form-item>
                </el-col>
                <el-col style="text-align: center" :span="2">-</el-col>
                <el-col class="time-s" :span="11">
                  <el-form-item prop="workEndTime">
                    <el-time-select
                      disabled
                      placeholder="结束时间"
                      v-model="form.workEndTime"
                      :picker-options="{
                          start: '00:00',
                          step: '00:05',
                          end: '23:55',
                          minTime: form.workStartTime
                        }"
                    ></el-time-select>
                  </el-form-item>
                </el-col>
              </el-form-item>

              <el-form-item class="line-wrap" label="网格备注" style="width: 97%">
                <el-input
                  v-model="form.gridRemark"
                  type="textarea"
                  :rows="4"
                  placeholder="请输入"
                  maxlength="254"
                  disabled
                ></el-input>
              </el-form-item>
              <!-- 人员作业班次 -->
              <el-form-item label="人员作业班次" style="width: 100%">
                <p class="prompt">（提示：该班次为人员排班所选信息，新增作业网格时请先保存基础信息再进行人员排班）</p>
              </el-form-item>
              <div
                class="item-wrap"
                v-for="(frequency, index) in form.frequency"
                :key="frequency.shiftName+''+index"
              >
                <div class="close-btn">
                  <i v-if="0" class="el-icon-close" @click.prevent="removeFrequency(frequency,index)"></i>
                </div>
                <el-form-item
                  :label="'班次名称'"
                  :prop="'frequency.' + index + '.shiftName'"
                  :rules="{
                  required: true, message: '不能为空', trigger: 'blur'
                }"
                >
                  <el-input v-model="frequency.shiftName" placeholder="请输入班次名称" disabled></el-input>
                </el-form-item>
                <el-form-item :label="'时间'" required>
                  <el-col class="time-s" :span="11">
                    <el-form-item
                      :prop="'frequency.' + index + '.shiftStartTime'"
                      :rules="{
                        required: true, message: '不能为空', trigger: 'change',
                      }"
                    >
                      <el-time-select
                        placeholder="起始时间"
                        v-model="frequency.shiftStartTime"
                        :picker-options="{
                          start: '00:00',
                          step: '00:05',
                          end: '23:55'
                        }"
                        @change="timeChange"
                        disabled
                      ></el-time-select>
                    </el-form-item>
                  </el-col>
                  <el-col style="text-align: center" :span="2">-</el-col>
                  <el-col class="time-s" :span="11">
                    <el-form-item
                      :prop="'frequency.' + index + '.shiftEndTime'"
                      :rules="{
                      required: true, message: '不能为空', trigger: 'change',
                      }"
                    >
                      <el-time-select
                        placeholder="结束时间"
                        v-model="frequency.shiftEndTime"
                        :picker-options="{
                          start: '00:00',
                          step: '00:05',
                          end: '23:55',
                          minTime: frequency.shiftStartTime
                        }"
                        disabled
                      ></el-time-select>
                    </el-form-item>
                  </el-col>
                </el-form-item>
              </div>
              <div  v-if="0" class="add-btn">
                <el-button class="btn" @click="addTab()">+ 新增</el-button>
              </div>
            </el-form>
          </div>
        </div>
        <!-- 地图部分 -->
        <ayl-map-polygon v-model="mapData" :enableDraw="false"></ayl-map-polygon>
      </div>

      <div class="tac mt40px">
        <el-button  v-if="0" type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <!-- <el-button class="btn-hw" @click="onSubmitAndNext">保存并录入下一条</el-button> -->
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import edit from "@/mixins/edit";
export default {
  mixins: [edit],
  data() {
    return {
      nav: [{ name: "编辑人员作业网格" }],
      //地图数据
      mapData: {
        polygonScope: null, //（1个）多边形具体的点，形如  [[118,36],[117,32],[116,113]]
        scope: null //（1个）围栏范围，经纬度点阵数组，形如  [[118,36],[117,32],[116,113]]
      },
      form: {
        gridName: null,
        bidId: null,
        sort: null,
        gridType: null,
        gridManager: null,
        contactPhone: null,
        workStartTime: null,
        workEndTime: null,
        scheduleWorkerNum: null,
        gridArea: null,
        cleanLevel: null,
        gridRemark: null,
        frequency: [
          {
            shiftName: null, //班次名称
            shiftStartTime: null,
            shiftEndTime: null
          }
        ]
      },

      cleaningNum: 0,
      // 所属标段下拉
      bidList: [],
      // 网格类型
      gridTypeOption: [
        { value: "0", label: "城中村" },
        { value: "1", label: "市政道路" },
        { value: "2", label: "重点区域" },
        { value: "3", label: "应急片区" },
        { value: "4", label: "其他" }
      ],
      // 保洁等级
      cleanLevelOption: [
        { value: "0", label: "特级" },
        { value: "1", label: "一级" },
        { value: "2", label: "二级" },
        { value: "3", label: "三级" }
      ],
      // 必填字段校验
      rules: {
        gridName: {
          required: true,
          message: "请输入网格名称",
          trigger: "blur"
        },
        bidId: {
          required: true,
          message: "请输入网格名称",
          trigger: "change"
        },
        gridType: {
          required: true,
          message: "请输入网格名称",
          trigger: "change"
        },
        gridManager: {
          required: true,
          message: "请输入网格名称",
          trigger: "blur"
        },
        contactPhone: {
          required: true,
          message: "请输入网格名称",
          trigger: "blur"
        },
        workStartTime: {
          required: true,
          message: "请选择工作时段",
          trigger: "change"
        },
        workEndTime: {
          required: true,
          message: "请选择工作时段",
          trigger: "change"
        },
        scheduleWorkerNum: {
          required: true,
          message: "请输入网格名称",
          trigger: "blur"
        }
        // scheduleWorkerNum: { required: true, message: "请输入网格名称", trigger: "blur" },
      }
    };
  },
  methods: {
    // 地图标段
    handleBidChage(val) {
      let self = this;
      self.form.bidId = val[1];
      if (self.mapData && self.form.bidId !== "") {
        self.mapData.move2center = true;
        self.$api_hw
          .common_getBidInfo({
            id: self.form.bidId
          })
          .then(res => {
            self.log("mk", res.bidCoordinateScope);
            try {
              self.mapData.scope = Array.isArray(res.bidCoordinateScope)
                ? res.bidCoordinateScope
                : JSON.parse(res.bidCoordinateScope);
            } catch (e) {
              self.mapData.scope = null;
            }
          })
          .catch(() => {
            self.mapData.scope = null;
          });
      }
    },

    timeChange() {
      console.log(this.form.frequency);
    },

    //新增人员班次
    addTab() {
      this.form.frequency.push({
        shiftName: null, //班次名称
        shiftStartTime: null,
        shiftEndTime: null
      });
    },
    //删除人员班次
    removeFrequency(item, index) {
      console.log(this.form.frequency);
      this.form.frequency.splice(index, 1);
    },

    /**
     * @summary【钩子函数，函数名固定】
     * @description 提交表单。此函数内，只需要专注操作表单数据this.form，不用管任何UI逻辑。
     * @author mabo
     */
    async submit() {
      let arrParams = this.form.frequency.map(item => {
        return {
          shiftName: item.shiftName,
          shiftStartTime: item.shiftStartTime,
          shiftEndTime: item.shiftEndTime
        };
      });

      let objParams = JSON.parse(JSON.stringify(this.form));
      let params = {
        sanitationWorkGridShiftVO: {
          sanitationShiftManageVOList: arrParams,
          sanitationWorkGridVO: objParams
        }
      };
      await this.$api_hw.personnelWork_updateGridShift(params).then(res => {
        this.$refs["form"].resetFields();
      });
    },
    /**
     * @summary【钩子函数，函数名固定】
     * @description 获取编辑的数据。此函数内，只需要专注操作表单数据this.form，不用管任何UI逻辑。
     * @author mabo
     */
    async getInfo() {
      let self =this
      //获取编辑数据并处理+绑定数据
      let formData = await this.$api_hw.personnelWork_getGridShiftInfo({
        gridId: this.$route.query.id
      });
      // 获取标段地图多边形
      await this.handleBidChage(["", formData.sanitationWorkGridVO.bidId]);
      /**
       * 把服务器端的Data 修改一下以方便编辑
       */
      self.form = _fixData(formData.sanitationWorkGridVO);
      function _fixData(d) {
        //先检查key是否存在，不存在赋值null
        ["gridCoordinateScope", "bidScope"].forEach(key => {
          if (!d.hasOwnProperty(key)) {
            d[key] = null;
          }
        });
        for (let key in d) {
          switch (key) {
            case "gridCoordinateScope":
              try {
                self.mapData.polygonScope = JSON.parse(d[key]);
              } catch (e) {
                self.mapData.polygonScope = null;
              }
              break;
            case "bidScope":
              self.mapData.move2center = true;
              try {
                self.mapData.scope = JSON.parse(d[key]);
              } catch (e) {
                self.mapData.scope = null;
              }
              break;
          }
        }
        return d;
      }

      // this.form = JSON.parse(JSON.stringify(formData.sanitationWorkGridVO));

      let arr = JSON.parse(
        JSON.stringify(formData.sanitationShiftManageVOList)
      ).map(item => {
        return {
          shiftName: item.shiftName,
          shiftStartTime: item.shiftStartTime,
          shiftEndTime: item.shiftEndTime,
          key: new Date()
        };
      });
      this.$set(this.form, "frequency", arr);
    }
  }
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block
  .map-popover
    position: absolute
    z-index: 2
    top: 12px
    right: 12px
    width: 180px
    height: 32px
    background: rgba(255,255,255,1)
    box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
    border-radius: 2px

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%

.add-btn
  width: 100%
  text-align: center
  .btn
    width: 64px
    height: 28px
    border: 1px solid rgba(26,188,156,1)
    border-radius: 4px
    color: #1ABC9C
.prompt
  color: #FF8C1A
  font-size: 12px

/deep/ .el-form
  display: flex
  flex-wrap: wrap
  .el-form-item 
    display: flex
    .el-form-item__content 
      flex: 1
      .el-select,
      .el-cascader--mini
        width: 100%
.item-wrap
  width: 97%
  margin-bottom: 20px
  padding: 15px 5px 0;
  border: 1px solid #1ABC9C
  box-sizing: border-box
  border-radius: 4px

  .close-btn
    text-align: right;
    font-size: 20px
    cursor: pointer
  /deep/ .el-form-item
    width: 65%
  /deep/.el-date-editor.el-input,
  .time-s /deep/ .el-form .el-form-item,
    width: 100%
.time-s /deep/.el-form-item,
.time-s /deep/ .el-date-editor.el-input
  width: 100%
/deep/ .el-input.is-disabled .el-input__inner,
/deep/ .el-range-editor.is-disabled input
    color: #607d8b
</style>
